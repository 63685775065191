<template>
  <div class="flex flex-grow justify-center items-center flex-col pl-500px">
    <div class="max-w-400 w-full mx-auto text-center px-9">
      <h2 class="text-4xl font-medium text-black-900 mb-3">Message sent</h2>
      <p class="text-black-900 text-base mb-8">
        We have sent a message with a link to reset your password to the address
        <span class="block font-medium"> addressemail@gmail.com </span>
      </p>

      <router-link
        to="/sign-in/"
        class="
          border
          rounded
          font-medium
          text-sm
          py-3
          w-full
          block
          max-w-212
          mx-auto
          text-white-900
          border-orange-900
          bg-orange-900
          hover:text-orange-900 hover:bg-opacity-0
        "
        >To sign in</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthMessageSent",
};
</script>
